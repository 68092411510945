import React from "react";
import ContentContainer from "./ContentContainer";
import Header from "../sections/Header";
import TitleDiv from "../controls/TitleDiv";
import HeadData from "../../data/HeadData";
import PrismicCategories from "../controls/Prismic/Categories";
import BlogSubscribeForm from "../forms/BlogSubscribeForm";
import RelatedPosts from "../controls/Prismic/RelatedPosts";
import Layout from "../layout/Layout";

const Container = ({ children, relatedContent }) => {
  return (
    <div className="flex flex-col-reverse lg:flex-row w-full max-w-content my-10 gap-x-10 gap-y-10">
      <SideList relatedContent={relatedContent} />
      <div className="flex flex-col w-full lg:w-9/12 px-10 lg:px-4 lg:pl-0 lg:pr-8">
        {children}
      </div>
    </div>
  );
};

const SideList = ({ relatedContent }) => {
  return (
    <div className="flex flex-col flex-grow px-4 mb-10 space-y-5">
      <PrismicCategories />
      <div className="mt-5">
        <BlogSubscribeForm />
      </div>
      <RelatedPosts relatedContent={relatedContent} />
    </div>
  );
};

const BlogContainer = ({
  isHome,
  title,
  children,
  location,
  parentText,
  relatedContent,
  path,
}) => {
  return (
    <>
      <Layout>
        <HeadData title={title} />
        <Header />
        <TitleDiv
          location={location}
          parentText={parentText}
          title={title}
          path={path}
        />
        <main className="flex flex-col items-center justify-center">
          {isHome && (
            <div className="w-full flex flex-col items-center justify-center">
              <ContentContainer
                titleText="NWP So Cal Blog"
                titleClassName="text-3xl font-sans font-normal"
                className="text-gray-50 px-2 lg:px-0 py-10 w-full flex flex-col items-start justify-center max-w-content"
              />
            </div>
          )}
          <Container relatedContent={relatedContent}>{children}</Container>
        </main>
      </Layout>
    </>
  );
};

export default BlogContainer;
